@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* lato-100 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Lato";
    font-style: normal;
    font-weight: 100;
    src: url("./assets/fonts/lato-v23-latin_latin-ext-100.eot"); /* IE9 Compat Modes */
    src: url("./assets/fonts/lato-v23-latin_latin-ext-100.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin_latin-ext-100.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("./assets/fonts/lato-v23-latin_latin-ext-100.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/lato-v23-latin_latin-ext-100.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("./assets/fonts/lato-v23-latin_latin-ext-100.svg#Lato") format("svg"); /* Legacy iOS */
  }
  /* lato-300 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/lato-v23-latin_latin-ext-300.eot"); /* IE9 Compat Modes */
    src: url("./assets/fonts/lato-v23-latin_latin-ext-300.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin_latin-ext-300.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("./assets/fonts/lato-v23-latin_latin-ext-300.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/lato-v23-latin_latin-ext-300.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("./assets/fonts/lato-v23-latin_latin-ext-300.svg#Lato") format("svg"); /* Legacy iOS */
  }
  /* lato-regular - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/lato-v23-latin_latin-ext-regular.eot"); /* IE9 Compat Modes */
    src: url("./assets/fonts/lato-v23-latin_latin-ext-regular.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin_latin-ext-regular.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("./assets/fonts/lato-v23-latin_latin-ext-regular.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/lato-v23-latin_latin-ext-regular.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("./assets/fonts/lato-v23-latin_latin-ext-regular.svg#Lato") format("svg"); /* Legacy iOS */
  }
  /* lato-700 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("./assets/fonts/lato-v23-latin_latin-ext-700.eot"); /* IE9 Compat Modes */
    src: url("./assets/fonts/lato-v23-latin_latin-ext-700.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin_latin-ext-700.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("./assets/fonts/lato-v23-latin_latin-ext-700.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/lato-v23-latin_latin-ext-700.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("./assets/fonts/lato-v23-latin_latin-ext-700.svg#Lato") format("svg"); /* Legacy iOS */
  }
  /* lato-900 - latin_latin-ext */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: url("./assets/fonts/lato-v23-latin_latin-ext-900.eot"); /* IE9 Compat Modes */
    src: url("./assets/fonts/lato-v23-latin_latin-ext-900.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin_latin-ext-900.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("./assets/fonts/lato-v23-latin_latin-ext-900.woff") format("woff"),
      /* Modern Browsers */ url("./assets/fonts/lato-v23-latin_latin-ext-900.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("./assets/fonts/lato-v23-latin_latin-ext-900.svg#Lato") format("svg"); /* Legacy iOS */
  }

  * {
    @apply font-lato;
  }
  body {
    @apply dark:bg-gray-900 lg:bg-gray-50 dark:lg:bg-gray-900 dark:text-gray-200;
  }
  a {
    @apply font-bold text-sky-500 transition-all duration-300 hover:text-sky-600 dark:text-sky-400 dark:hover:text-sky-300;
  }
  p {
    @apply text-zinc-800 dark:text-gray-100;
  }
  .tw-btn-basic {
    @apply text-center text-sm font-medium;
  }
  .tw-link {
    @apply font-bold text-sky-500 transition-all duration-300 hover:text-sky-600 dark:text-sky-400 dark:hover:text-sky-300;
  }
  .tw-link-tercier {
    @apply font-bold text-slate-500 underline transition-all duration-300 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-500;
  }
  .tw-link-dark {
    @apply font-bold text-slate-900 underline transition-all duration-300 hover:text-slate-700 dark:text-slate-100 dark:hover:text-slate-200;
  }
  .tw-link-red {
    @apply font-bold text-red-600 transition-all duration-300 hover:text-red-700 dark:text-red-700 dark:hover:text-red-600;
  }
  .tw-btn-primary {
    @apply rounded bg-sky-500 px-4 py-2 text-center text-sm font-medium uppercase text-white shadow transition-all duration-300 hover:bg-sky-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-sky-100 dark:bg-sky-500 dark:hover:bg-sky-600 dark:focus:ring-sky-800;
  }
  .tw-btn-primary-disabled {
    @apply cursor-not-allowed rounded bg-sky-300 px-4 py-2 text-center text-sm font-medium uppercase text-white dark:bg-sky-300;
  }
  .tw-btn-primary-small {
    @apply rounded bg-sky-500 px-3 py-1 text-center text-xs font-medium uppercase text-white shadow transition-all duration-300 hover:bg-sky-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-sky-100 dark:bg-sky-500 dark:hover:bg-sky-600 dark:focus:ring-sky-800;
  }
  .tw-btn-primary-small-disabled {
    @apply cursor-not-allowed rounded bg-sky-300 px-3 py-1 text-center text-xs font-medium uppercase text-white dark:bg-sky-300;
  }
  .tw-btn-primary-outline {
    @apply rounded border border-sky-500 bg-white px-4 py-2 text-center text-sm font-medium uppercase text-sky-500 shadow transition-all duration-300 hover:border-sky-600 hover:bg-gray-100 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-sky-100 dark:border-sky-500 dark:bg-gray-800 dark:text-sky-400 dark:hover:border-sky-600 dark:hover:bg-gray-900 dark:focus:ring-sky-800;
  }
  .tw-btn-primary-outline-small {
    @apply rounded border border-sky-500 bg-white px-3 py-1 text-center text-xs font-medium uppercase text-sky-500 shadow transition-all duration-300 hover:border-sky-600 hover:bg-gray-100 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-sky-100 dark:border-sky-500 dark:bg-gray-800 dark:text-sky-400 dark:hover:border-sky-600 dark:hover:bg-gray-900 dark:focus:ring-sky-800;
  }
  .tw-btn-secondary {
    @apply rounded border border-gray-300 bg-white px-4 py-2 text-center text-sm font-medium uppercase text-gray-600 shadow transition-all duration-300 hover:bg-gray-100 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-900 dark:focus:ring-gray-700;
  }
  .tw-btn-secondary-small {
    @apply rounded border border-gray-300 bg-white px-3 py-1 text-center text-xs font-medium uppercase text-gray-600 shadow transition-all duration-300 hover:bg-gray-100 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-900 dark:focus:ring-gray-700;
  }
  .tw-btn-social {
    @apply flex items-center justify-center space-x-3 rounded border border-slate-500 bg-white px-4 py-2 text-sm font-medium uppercase text-zinc-800 shadow transition-all duration-300 hover:bg-gray-100 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-slate-500 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-900 dark:focus:ring-gray-700;
  }
  .tw-btn-tercier {
    @apply rounded bg-slate-500 px-4 py-2 text-center text-sm font-medium uppercase text-white shadow transition-all duration-300 hover:bg-slate-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus:ring-slate-800;
  }
  .tw-btn-tercier-disabled {
    @apply cursor-not-allowed rounded bg-slate-400 px-4 py-2 text-center text-sm font-medium uppercase text-white dark:bg-slate-400;
  }
  .tw-btn-tercier-small {
    @apply rounded bg-slate-500 px-3 py-1 text-center text-xs font-medium uppercase text-white shadow transition-all duration-300 hover:bg-slate-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus:ring-slate-800;
  }
  .tw-btn-tercier-small-disabled {
    @apply cursor-not-allowed rounded bg-slate-400 px-3 py-1 text-center text-xs font-medium uppercase text-white dark:bg-slate-400;
  }
  .tw-btn-dark {
    @apply rounded bg-zinc-800 px-4 py-2 text-center text-sm font-medium uppercase text-white shadow transition-all duration-300 hover:bg-zinc-900 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-zinc-300 dark:bg-zinc-800 dark:hover:bg-zinc-900 dark:focus:ring-zinc-900;
  }
  .tw-btn-dark-disabled {
    @apply cursor-not-allowed rounded bg-zinc-500 px-4 py-2 text-center text-sm font-medium uppercase text-white dark:bg-zinc-500;
  }
  .tw-btn-dark-small {
    @apply rounded bg-zinc-800 px-3 py-1 text-center text-xs font-medium uppercase text-white shadow transition-all duration-300 hover:bg-zinc-900 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-zinc-300 dark:bg-zinc-800 dark:hover:bg-zinc-900 dark:focus:ring-zinc-900;
  }
  .tw-btn-dark-small-disabled {
    @apply cursor-not-allowed rounded bg-zinc-500 px-3 py-1 text-center text-xs font-medium uppercase text-white dark:bg-zinc-500;
  }
  .tw-btn-red {
    @apply rounded bg-red-600 px-4 py-2 text-center text-sm font-medium uppercase text-white shadow transition-all duration-300 hover:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-red-100 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800;
  }
  .tw-btn-red-disabled {
    @apply cursor-not-allowed rounded bg-red-400 px-4 py-2 text-center text-sm font-medium uppercase text-white dark:bg-red-400;
  }
  .tw-btn-red-small {
    @apply rounded bg-red-600 px-3 py-1 text-center text-xs font-medium uppercase text-white shadow transition-all duration-300 hover:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-red-100 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800;
  }
  .tw-btn-red-small-disabled {
    @apply cursor-not-allowed rounded bg-red-400 px-3 py-1 text-center text-xs font-medium uppercase text-white dark:bg-red-400;
  }
  .tw-btn-circle-primary {
    @apply inline-flex items-center justify-center rounded-full bg-sky-500 p-2 text-center text-sm font-medium text-white shadow transition-all duration-300 hover:bg-sky-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-sky-100 dark:bg-sky-500 dark:hover:bg-sky-600 dark:focus:ring-sky-800;
  }
  .tw-btn-circle-tercier {
    @apply inline-flex items-center justify-center rounded-full bg-gray-600 p-2 text-center text-sm font-medium text-white shadow transition-all duration-300 hover:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900;
  }
  .tw-btn-pill-secondary {
    @apply flex-shrink-0 rounded-full border border-gray-300 bg-white px-4 py-2 text-center text-sm font-medium text-gray-900 transition-all duration-300 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-900 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-900 dark:focus:ring-gray-700;
  }
  .tw-btn-pill-secondary-small {
    @apply flex-shrink-0 rounded-full border border-gray-300 bg-white px-2.5 py-1 text-center text-xs font-medium text-gray-900 transition-all duration-300 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-900 dark:bg-gray-800 dark:text-white dark:hover:border-gray-600 dark:hover:bg-gray-900 dark:focus:ring-gray-700;
  }
  .tw-btn-pill-tercier {
    @apply flex-shrink-0 rounded-full bg-slate-500 px-4 py-2 text-center text-sm font-medium text-white transition-all duration-300 hover:bg-slate-600 focus:outline-none focus:ring-4 focus:ring-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus:ring-slate-800;
  }
  .tw-btn-pill-tercier-small {
    @apply flex-shrink-0 rounded-full bg-slate-500 px-2.5 py-1 text-center text-xs font-medium text-white transition-all duration-300 hover:bg-slate-600 focus:outline-none focus:ring-4 focus:ring-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus:ring-slate-800;
  }
  .tw-btn-pill-primary-small {
    @apply flex-shrink-0 rounded-full bg-sky-500 px-2.5 py-1 text-center text-xs font-medium text-white transition-all duration-300 hover:bg-sky-600 focus:outline-none focus:ring-4 focus:ring-sky-100 dark:bg-sky-500 dark:hover:bg-slate-600 dark:focus:ring-sky-800;
  }
  .tw-btn-pill-primary-outlined-small {
    @apply flex-shrink-0 rounded-full bg-white px-2.5 py-1 text-center text-xs font-medium text-sky-600 transition-all duration-300 border border-sky-500 dark:bg-gray-800 dark:hover:bg-sky-500 hover:text-white hover:bg-sky-500 focus:outline-none focus:ring-4 focus:ring-sky-100 ;
  }
  .tw-btn-pill-green-small {
    @apply flex-shrink-0 rounded-full bg-green-600 px-2.5 py-1 text-center text-xs font-medium text-white transition-all duration-300 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-100 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800;
  }
  .tw-btn-pill-red-small {
    @apply flex-shrink-0 rounded-full bg-red-600 px-2.5 py-1 text-center text-xs font-medium text-white transition-all duration-300 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-100 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800;
  }
  .tw-btn-pill-orange-small {
    @apply flex-shrink-0 rounded-full bg-orange-600 px-2.5 py-1 text-center text-xs font-medium text-white transition-all duration-300 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-100 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800;
  }
  .tooltip {
    @apply absolute z-10 inline-block rounded-lg bg-gray-600 px-3 py-2 text-sm font-medium text-white shadow-md transition-opacity duration-300 dark:bg-gray-600;
  }
  label {
    @apply mb-1.5 block text-left text-sm font-medium text-zinc-800 dark:text-zinc-300;
  }
  label span {
    @apply text-zinc-500 dark:text-zinc-500;
  }
  input[type="checkbox"] ~ label,
  input[type="radio"] ~ label {
    @apply mb-0 ml-3;
  }
  .peer ~ label {
    @apply mb-0;
  }
  input[type="checkbox"]:disabled ~ label,
  input[type="radio"]:disabled ~ label {
    @apply text-zinc-500 dark:text-zinc-400;
  }
  .has-error input[type="radio"] {
    @apply border-red-500 dark:border-red-500;
  }
  input[type="checkbox"] ~ label p,
  input[type="radio"] ~ label p,
  input[type="checkbox"] ~ label span,
  input[type="radio"] ~ label span,
  input[type="checkbox"] ~ label div,
  input[type="radio"] ~ label div {
    @apply mt-1 text-xs font-normal text-zinc-500 dark:text-zinc-400;
  }
  input[type="checkbox"]:disabled ~ label p,
  input[type="radio"]:disabled ~ label p,
  input[type="checkbox"]:disabled ~ label span,
  input[type="radio"]:disabled ~ label span,
  input[type="checkbox"]:disabled ~ label div,
  input[type="radio"]:disabled ~ label div {
    @apply text-zinc-300 dark:text-zinc-600;
  }
  .tw-input, .PhoneInputInput {
    @apply block w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2.5 text-sm text-zinc-800 placeholder-gray-400 focus:border-sky-500 focus:shadow-sm focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-zinc-300 dark:placeholder-gray-400 dark:focus:border-sky-500 dark:focus:outline-none;
  }
  .tw-input:disabled, .PhoneInputInput:disabled {
    @apply text-gray-400 dark:text-gray-400;
  }
  .has-success .tw-input {
    @apply border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 dark:border-green-500 dark:bg-gray-700 dark:text-green-400 dark:placeholder-green-500 dark:focus:border-green-500 dark:focus:outline-none;
  }
  .has-error .tw-input, .has-error .PhoneInputInput {
    @apply border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 dark:border-red-500 dark:bg-gray-700 dark:text-red-500 dark:placeholder-red-500 dark:focus:border-red-500 dark:focus:outline-none;
  }
  .has-helper-text p {
    @apply mt-2 text-sm text-gray-500 dark:text-gray-400;
  }
  .has-success p {
    @apply mt-2 text-sm text-green-600 dark:text-green-500;
  }
  .has-error p {
    @apply mt-2 text-sm text-red-600 dark:text-red-500;
  }
  select,
  input[type="date"] {
    -webkit-appearance: none;
    appearance: none;
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem) center;
  }
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  select {
    background-image: url("./assets/img/icons/chevron-down.svg");
  }
  input[type="date"] {
    background-image: url("./assets/img/icons/calendar-datepicker.svg");
  }
  input[type="checkbox"],
  input[type="radio"] {
    @apply h-5 w-5 border-gray-300 bg-gray-100 text-sky-500 focus:ring-transparent dark:border-gray-500 dark:bg-gray-700 dark:text-sky-500;
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    @apply text-sky-200 dark:text-sky-700;
  }
  input[type="checkbox"] {
    @apply rounded;
  }
  .tw-icon {
    @apply ml-auto inline-flex justify-center items-center h-8 w-8 rounded-lg bg-gray-50 p-1.5 text-gray-500 transition-all duration-300 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600;
  }
  .tooltip .tw-icon {
    @apply bg-gray-600 text-white hover:bg-gray-700 focus:ring-gray-400 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500;
  }
  .tw-alert-info {
    @apply flex rounded-lg border border-sky-100 bg-sky-50 p-4 text-sky-700 dark:border-gray-600 dark:bg-gray-700 dark:text-sky-400;
  }
  .tw-alert-info a {
    @apply font-bold text-sky-700 transition-all duration-300 hover:text-sky-800 dark:text-sky-500 dark:hover:text-sky-400;
  }
  .tw-alert-info .tw-icon {
    @apply bg-sky-50 text-sky-500 hover:bg-sky-200 focus:ring-sky-400 dark:bg-gray-700 dark:text-sky-400 dark:hover:bg-gray-600;
  }
  .tw-alert-danger {
    @apply flex rounded-lg border border-red-100 bg-red-50 p-4 text-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-red-400;
  }
  .tw-alert-danger a {
    @apply font-bold text-red-700 transition-all duration-300 hover:text-red-800 dark:text-red-500 dark:hover:text-red-400;
  }
  .tw-alert-danger .tw-icon {
    @apply bg-red-50 text-red-500 hover:bg-red-200 focus:ring-red-400 dark:bg-gray-700 dark:text-red-400 dark:hover:bg-gray-600;
  }
  .tw-alert-success {
    @apply flex rounded-lg border border-green-100 bg-green-50 p-4 text-green-700 dark:border-gray-600 dark:bg-gray-700 dark:text-green-400;
  }
  .tw-alert-success a {
    @apply font-bold text-green-700 transition-all duration-300 hover:text-green-800 dark:text-green-500 dark:hover:text-green-400;
  }
  .tw-alert-success .tw-icon {
    @apply bg-green-50 text-green-500 hover:bg-green-200 focus:ring-green-400 dark:bg-gray-700 dark:text-green-400 dark:hover:bg-gray-600;
  }
  .tw-alert-warning {
    @apply flex rounded-lg border border-orange-100 bg-orange-50 p-4 text-orange-700 dark:border-gray-600 dark:bg-gray-700 dark:text-orange-400;
  }
  .tw-alert-warning a {
    @apply font-bold text-orange-700 transition-all duration-300 hover:text-orange-800 dark:text-orange-500 dark:hover:text-orange-400;
  }
  .tw-alert-warning .tw-icon {
    @apply bg-orange-50 text-orange-500 hover:bg-orange-200 focus:ring-orange-400 dark:bg-gray-700 dark:text-orange-300 dark:hover:bg-gray-600;
  }
  nav ul li a.active {
    @apply border-sky-500;
  }
  ul li a.active svg.hidden {
    @apply block;
  }
  .dropdown-navbar-branch {
    margin-top: -10px !important;
    border-top-left-radius: 0 !important;
    -moz-border-top-left-radius: 0 !important;
    -webkit-border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    -moz-border-top-right-radius: 0 !important;
    -webkit-border-top-right-radius: 0 !important;
  }
  .dropdownNavbarUser {
    margin-top: -4px !important;
  }
  .dropdown-countries {
    margin-left: 92px !important;
  }
  .dropdown-mobile-footer-more {
    margin-bottom: -5px !important;
    border-bottom-left-radius: 0 !important;
    -moz-border-bottom-left-radius: 0 !important;
    -webkit-border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    -moz-border-bottom-right-radius: 0 !important;
    -webkit-border-bottom-right-radius: 0 !important;
  }
  .dropdown-menu {
    @apply bg-white dark:bg-gray-700 dark:text-white
  }
  .tw-card {
    @apply w-full rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800;
  }
  .background-rectangle {
    @apply lg:container lg:mx-auto lg:min-h-screen lg:tw-card lg:rounded-none lg:border-b-0 pb-14 pt-14 xl:pb-28;
  }
  .main-default {
    @apply lg:min-h-screen pb-0 lg:pb-14 pt-14;
  } 
  .main-signed-in {
    @apply lg:container lg:mx-auto lg:min-h-screen lg:tw-card lg:rounded-none lg:border-b-0 pb-14 pt-14 xl:pt-28;
  }
  .main-signed-in-no-clinic {
    @apply lg:container lg:mx-auto lg:min-h-screen lg:tw-card lg:rounded-none lg:border-b-0 pb-14 pt-14 xl:pb-28;
  }
  .main-signed-in-appointments {
    @apply lg:container lg:mx-auto lg:min-h-screen pb-14 pt-14 xl:pb-28 xl:pt-28;
  }
  .main-signed-in-mobile-page {
    @apply lg:container lg:mx-auto lg:min-h-screen pb-14 pt-14;
  }
  main ~ .inset-0.z-40 {
    @apply bg-gray-900/50;
  }
  .tw-table-container {
    @apply overflow-auto border border-gray-200 dark:border-gray-700 md:rounded-lg;
  }
  .tw-table {
    @apply min-w-full divide-y divide-gray-200 dark:divide-gray-700;
  }
  .tw-thead {
    @apply bg-gray-50 dark:bg-gray-800;
  }
  .tw-tbody {
    @apply bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900;
  }
  .tw-th {
    @apply py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-zinc-500 dark:text-zinc-500;
  }

 .tw-tab-active {
    @apply inline-block mb-2 lg:mb-0 px-4 lg:px-6 py-2 lg:py-3 text-sky-500 border-b-2 border-sky-500 rounded-t-lg active dark:text-sky-400 dark:border-sky-500;
  }

 .tw-tab-inactive {
    @apply inline-block mb-2 lg:mb-0 px-4 lg:px-6 py-2 lg:py-3 border-b-2 border-transparent rounded-t-lg hover:text-gray-700 hover:border-gray-200 text-gray-700 dark:text-gray-200 dark:hover:text-gray-200 dark:hover:border-gray-700 transition-all duration-300;
  }

  /* Doctor menu */
  .tw-menu-active {
    border-color: rgb(12 162 254);
  }

  /* The id selector has higher specificity than classes */
  /* Useful to ignore margins coming from tailwind spacing classes declared in parent components when using global !important setting (e.g. in modals) */
  /* The only alternative (inline !important style) is no longer supported in React. */
  #ignore-spacing {
    @apply m-0 !important;
  }

  /* Modal */
  .tw-modal {
    @apply fixed left-0 top-0 z-[1055] h-full w-full m-0 overflow-y-auto overflow-x-hidden outline-none bg-gray-600 bg-opacity-60;
  }

  .tw-modal-header {
    @apply relative top-0 left-0 right-0 z-50 xl:rounded-t border-b border-gray-200 dark:border-gray-700 w-full xl:w-auto h-14 xl:h-auto bg-white dark:bg-gray-800 px-4 xl:px-5 xl:py-5 flex items-center justify-between
  }

  .tw-modal-content {
    @apply relative w-auto opacity-100 mt-32 divide-y dark:divide-gray-700 bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 w-full w-auto h-14 h-auto;
  }

  .tw-modal-footer {
    @apply xl:rounded-b border-t border-gray-200 dark:border-gray-700 w-full xl:w-auto bg-white dark:bg-gray-800 flex flex-col xl:flex-row justify-center xl:justify-between gap-4 items-center p-4 xl:p-5;
  }

  /* Default spacings for modal body */
  .modal-body {
    @apply px-4 xl:px-5 py-8 space-y-6
  }

  /* This button is on the right side on desktop and on the top on mobile view. The button is also wider than the sub button. */
  .modal-main-button {
    @apply xl:min-w-48 xl:w-auto w-full order-1 xl:order-2
  }

  /* Same as modal-main-button, but it's not wide as default */
  .modal-main-button-small {
    @apply xl:w-auto w-full order-1 xl:order-2
  }

  /* This button is on the left side on desktop and on the bottom on mobile view. This button has normal width. */
  .modal-sub-button {
    @apply xl:w-auto w-full order-2 xl:order-1
  }

  /* Same as modal-sub-button, but it's wide as default */
  .modal-sub-button-wide {
    @apply xl:min-w-48 xl:w-auto w-full order-2 xl:order-1
  }

  /* Responsive button group (e.g. for multiple main buttons) */
  .modal-button-group {
    @apply flex flex-col xl:flex-row justify-between gap-4 order-1 xl:order-2 w-full xl:w-auto
  }

  /* Auto growing (controlled) textarea */
  .auto-grow-wrap {
    @apply grid;
  }
  .auto-grow-wrap::after {
    content: attr(data-text) " ";
    @apply whitespace-pre-wrap invisible 
  }
  .auto-grow-wrap > textarea {
    @apply resize-none overflow-hidden
  }
  .auto-grow-wrap > textarea,
  .auto-grow-wrap::after {
    @apply block w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2.5 text-sm text-zinc-800 placeholder-gray-400 focus:border-sky-500 focus:shadow-sm focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-zinc-300 dark:placeholder-gray-400 dark:focus:border-sky-500 dark:focus:outline-none;
    grid-area: 1 / 1 / 2 / 2;
  }

  /* Video consultation */

  /* Call control buttons */
  .video-consultation-btn-group {
    @apply flex space-x-4 justify-center items-center;
  }

  .video-consultation-btn {
    @apply rounded-full bg-sky-500 p-2 text-center text-sm font-medium uppercase text-white shadow duration-300 hover:bg-sky-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-sky-100 dark:bg-sky-500 dark:hover:bg-sky-600 dark:focus:ring-sky-800;
  }

  .video-consultation-btn-green {
    @apply rounded-full bg-green-500 p-2 text-center text-sm font-medium uppercase text-white shadow duration-300 hover:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-green-200 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-800;
  }

  .video-consultation-btn-orange {
    @apply rounded-full bg-orange-500 p-2 text-center text-sm font-medium uppercase text-white shadow duration-300 hover:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-orange-200 dark:bg-orange-500 dark:hover:bg-orange-600 dark:focus:ring-orange-800;
  }

  .video-consultation-btn-gray {
    @apply rounded-full bg-slate-500 p-2 text-center text-sm font-medium uppercase text-white shadow duration-300 hover:bg-slate-600 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600 dark:focus:ring-slate-800;
  }

  .video-consultation-btn-disabled {
    @apply cursor-not-allowed rounded-full bg-zinc-500 p-2 text-center text-sm font-medium uppercase text-white shadow duration-300 dark:bg-zinc-500;
  }

  .video-consultation-btn-red {
    @apply rounded-full bg-red-600 p-2 text-center text-sm font-medium uppercase text-white shadow duration-300 hover:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-4 focus:ring-red-100 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800;
  }

  /* Disable default controls for full screen videos */
  /* Errors/warnings can safely be ignored or disabled */

  /* Webkit */
  .fullscreen-video::-webkit-media-controls {
    display: none !important;
  }

  /* Webkit */
  .fullscreen-video::-webkit-media-controls-overlay-enclosure {
    display: none !important;
  }

  /* Firefox */
  .fullscreen-video::-moz-media-controls {
    display: none !important;
  }

  /* Edge */
  .fullscreen-video::-ms-media-controls {
    display: none !important;
  }

  /* Opera */
  .fullscreen-video::-o-media-controls {
    display: none !important;
  }

  /* Custom volume slider */

  .volume-slider {
    -webkit-appearance: none; /* Remove default appearance */
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 8rem;
    outline: none;
  }

  input[type="range"] {
    border-radius: 16px;
    height: 6px;
    background: #ccc;
  }

  /* Progress: Firefox */
  input[type="range"]::-moz-range-progress {
    background: rgba(0,0,0,0);
  }

  /* Thumb: Webkit */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default appearance */
    appearance: none;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border-radius: 50%;
    transition: .2s ease-in-out;
  }

  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border-radius: 50%;
    transition: .2s ease-in-out;
  }

  /* Hover, active & focus Thumb: Webkit */
  input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 6px rgba(255,255,255, .1)
  }
  input[type="range"]:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 8px rgba(255,255,255, .1)
  }
  input[type="range"]:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 8px rgba(255,255,255, .1)
  }

  /* Hover, active & focus Thumb: Firefox */
  input[type="range"]::-moz-range-thumb:hover {
    box-shadow: 0 0 0 6px rgba(255,255,255, .1)
  }
  input[type="range"]:active::-moz-range-thumb {
    box-shadow: 0 0 0 8px rgba(255,255,255, .1)
  }
  input[type="range"]:focus::-moz-range-thumb {
    box-shadow: 0 0 0 8px rgba(255,255,255, .1)
  }

}
